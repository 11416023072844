import { useState } from "react";
import React from "react";
import { withSlideUp } from "../../Layouts";
import imgUrl from "../../assets/Group 47.png";
import backgroundUrl from "../../assets/Group 48.png";

const EuronKeyMilestone: React.FC = () => {
  const [rightPos, setRightPos] = useState(0);
  return (
    <>
      <section id="roadmap" className="flex relative flex-col items-center self-stretch px-20 max-lg:p-14 max-lg:px-5 pt-32 pb-5 mt-64 w-full min-h-[1128px]">
        <div
          className={`flex gap-2.5 self-start text-2xl font-medium tracking-wider text-teal-300`}
        >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
            className="object-contain shrink-0 aspect-squarew-[18px]"
            alt=""
          />
          <h5 className={`text-h5 text-teal-300`}>EURON’S KEY MILESTONES</h5>
        </div>
        <div className="relative w-full border-radius-4 h-auto overflow-hidden">
           <img loading="lazy" src={imgUrl} className="block w-[200%] h-auto max-md:max-w-none relative" alt="" style={{right: rightPos + '%'}} />  
        </div>
        <div className="flex gap-3 float-right self-end mt-10 max-w-full z-[10] md:hidden">
          <button 
            className="flex flex-1 gap-2 justify-center items-center px-7 py-3 rounded-3xl border border-solid bg-white bg-opacity-10 border-white border-opacity-10 min-h-[52px] rotate-[3.141592653589793rad] max-md:px-5"
            onClick={()=>{
              setRightPos(old=>{
                if(old-20 <= 0)
                  return 0;
                else
                  return old-20
              }
              )
            }} 
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d7e8f8926537da681012da85dcdccf79e943398e577883b23d7db592db5f735?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
              className="object-contain self-stretch my-auto w-7 aspect-square"
              style={{ transform: "rotate(180deg)" }}
              alt="Previous testimonial"
            />
          </button>
          <button 
            className="flex flex-1 gap-2 justify-center items-center px-7 py-3 rounded-3xl border border-solid bg-white bg-opacity-10 border-white border-opacity-10 min-h-[52px] max-md:px-5"
            onClick={()=>{
            setRightPos((old)=>{
              if(old + 20 >= 100)
                return 100;
              else
                return old+20
            })
            }}  
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d86c21feef23020cd43dd25fb50eeed9b5f3e9174ac4442c7eabe8803483f4ff?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
              className="object-contain self-stretch my-auto w-7 aspect-square"
              alt="Next testimonial"
            />
          </button>
        </div>
        <div
          style={{
            backgroundImage: `url("${backgroundUrl}")`,
            backgroundRepeat: "no-repeat",
          }}
          className={[
            "absolute",
            "w-[1940.5px]",
            "h-[1730px]",
            "max-lg:top-[290px]",
						"top-[650px]",
          ].join(" ")}
        />
      </section>
    </>
  );
};

export default withSlideUp(EuronKeyMilestone);
