import React from "react";
import { withSlideUp } from "../../Layouts";
import Vector1 from "../../assets/Vector1.png";
import Logo from "../../assets/logo.png"; // Adjust path as needed

const TermsOfUse: React.FC = () => {
  return (
    <section className="relative px-4 py-20 w-full max-w-[1711px] max-md:max-w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Background Image */}
      <div
        style={{
          backgroundImage: `url("${Vector1}")`,
        }}
        className="absolute w-full h-full bg-cover bg-center opacity-20 top-0 left-0"
      />
      <a href="https://euron.io"><img src={Logo} alt="Euron Logo" className="absolute top-5 left-5 w-64 ml-6 md:ml-10" /></a>

      <div className="relative flex items-center px-10 max-lg:px-5 gap-5 max-lg:mt-5 mt-[-10px] max-lg:flex-col-reverse">
        <div className="flex flex-col w-full max-md:w-full">
          <div className="flex flex-col items-start">
            {/* Large Background Text */}
            {/* Main Content */}
            <div className="relative max-w-4xl mx-auto bg-gray-800 bg-opacity-90 rounded-lg p-8 shadow-lg mt-16 text-left">
              <header className="mb-8">
                <h1 className="text-4xl font-bold text-gray-100">Euron Token Project</h1>
                <p className="text-lg text-gray-400 mt-2">Terms of Use</p>
              </header>

              <div className="space-y-8 text-gray-200">
                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">1. Introduction</h2>
                  <p className="text-gray-300">
                    These Terms of Use ("Terms") govern your use of the Euron website, token sale, and related services. By participating in the Euron presale, you agree to these Terms. If you do not agree, please refrain from using our services.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">2. Eligibility</h2>
                  <p className="text-gray-300">
                    You must be at least 18 years old and comply with all relevant laws in your jurisdiction to participate in the Euron presale.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">3. Risks</h2>
                  <p className="text-gray-300">
                    Participating in the Euron token sale involves financial risk. You acknowledge and accept these risks, including potential loss of your entire investment.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">4. Token Utility</h2>
                  <p className="text-gray-300">
                    Euron tokens are intended solely for use within the Euron ecosystem. They are not intended to be an investment, security, or a representation of equity.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">5. Intellectual Property</h2>
                  <p className="text-gray-300">
                    All content on the Euron website, including logos, graphics, and text, is the intellectual property of Euron and may not be used without our permission.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">6. Liability</h2>
                  <p className="text-gray-300">
                    Euron is not liable for any loss or damage arising from your participation in the token sale or use of the website, except as required by law.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">7. Changes to Terms</h2>
                  <p className="text-gray-300">
                    Euron reserves the right to modify these Terms at any time. We will notify you of any changes by updating the "Effective Date" at the top of the Terms.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">8. Governing Law</h2>
                  <p className="text-gray-300">
                    These Terms are governed by the laws of the jurisdiction in which Euron is established. Any disputes arising under these Terms will be resolved in accordance with these laws.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">9. Contact Us</h2>
                  <p className="text-gray-300">
                    For questions regarding these Terms, please contact us at <a href="mailto:info@euron.io" className="text-blue-500 hover:underline">info@euron.io</a>.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(TermsOfUse);
