import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Higher-Order Component for slide-up animation
export function withSlideUp<P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>) {
	return (props: P & any) => {
		// Using react-intersection-observer's hook to detect visibility
		const { ref, inView } = useInView({
			triggerOnce: true, // Trigger only once when the component comes into view
			threshold: 0.1, // Trigger when 10% of the component is visible
		});

		return (
			<motion.div
				ref={ref}
				initial={{ opacity: 0, y: 100 }} // Start off-screen below
				animate={inView ? { opacity: 1, y: 0 } : {}} // Animate up when in view
				transition={{ duration: 0.5 }} // Smooth transition
			>
				<WrappedComponent {...(props as P)} />
			</motion.div>
		);
	};
}
